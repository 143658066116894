.fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
  float: right;
}
.fc-dayGrid-view .fc-body .fc-row {
  min-height: 4em;
}

.fc-theme-standard .fc-scrollgrid {
  border-left: 0;
}
